.body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 600px;
    background-color: #f5f5f5;
    font-family: "Roboto", sans-serif;
    background: linear-gradient(to bottom, #373b44, #4286f4);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  input[type="number"] {
    text-align: left !important;
  }
  
  .containerr {
    width: 100%;
    background: linear-gradient(to bottom, #373b44, #4286f4);
  }
  .inner-container {
    padding: 0 25px 0 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .c-box {
    position: relative;
    top: -50px;
    background-color: #ebebeb;
    padding: 20px;
    border-radius: 10px;
    width: 55rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .verify {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-verify {
    background-color: #1b2642;
    color: white;
    border-radius: 5px;
    padding: 10px;
    border: none;
    font-size: 15px;
    margin: 20px;
    cursor: pointer;
    width: auto;
  }
  .btn-verify:hover {
    background-color: #131d35;
    color: white;
  }
  .btn-verify:focus {
    outline: none;
  }
  .btn-verify:active {
    border: none;
  }
  
  ::-webkit-scrollbar {
    width: 10px;
  }
  .img-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 1px solid #ccc;
    flex: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: white;
  }
  .invisible {
    display: none;
  }
  .fieldWidth {
    width: 242px;
  }
  
  .big-img-wrapper {
    width: 36px;
    height: 45px;
    overflow: hidden;
    border: 1px solid #ccc;
    flex: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .input-field {
    flex: 85%;
    border-radius: 0px 5px 5px 0px !important;
    width: 100%;
    height: auto !important;
  }
  .flex {
    display: flex;
    width: 300px;
    height: 45px;
  }
  .field-container {
    display: flex;
    align-items: center;
    width: 400px !important;
    justify-content: space-between;
  }
  .label {
    white-space: nowrap;
    align-self: center;
    display: inline-block;
    width: 100px;
    text-align: right;
    margin-right: 10px;
  }
  .bday {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 370px;
    height: 45px;
  }
  .b-bold {
    margin: 0px 14px 0px 2px;
  }
  ::-webkit-scrollbar-thumb {
    background: gray;
  }
  .select {
    width: 70% !important;
    margin: 0px 10px 0px 10px;
    height: 45px !important;
  }
  .pair {
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
  }
  .date-size {
    height: 45px !important;
  }
  .heading {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    background-color: #1b2642;
  }
  h4 {
    color: white;
    font-size: 25px;
    margin-bottom: 40px;
  }
  .bold {
    font-weight: bold;
  }
  .logos {
    display: flex;
    justify-content: space-around;
    padding-top: 10px;
    width: 100%;
    padding-bottom: 20px;
    margin-right: 20px;
  }
  .logo {
    width: 60px;
    object-fit: contain;
  }
  .logo-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sam {
    width: 90px;
  }
  .vermont {
    width: 70px;
  }
  .logout{
    display: none;
  }